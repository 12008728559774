import React from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import {colors} from "../../variables";

const AboutSection = styled(({className, secData, lang}) => {
    return (<section className={className}>
        <div className="bottom-line" />
        <div className="about-section-wrapper">
            <div className="title-placeholder">
                <SectionTitle>{secData.title[lang]}</SectionTitle>
            </div>
            <div className="text-placeholder">
                <div className="text-wrapper" dangerouslySetInnerHTML={{__html: secData.content[lang]}} />
            </div>
        </div>
    </section>)
})`
  position:relative;
  .about-section-wrapper {
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: flex-start;
    max-width: 1200px;
    margin:auto;
    border-right:1px solid ${colors.borderLineLight};
    border-left: 1px solid ${colors.borderLineLight};
    padding:140px 25px 100px 25px;
  }
  .title-placeholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight:bold;
    font-size: calc(34px + (96 - 34) * ((100vw - 300px) / (1600 - 300)));
    width:100%;
    @media (min-width:960px) {
      width:40%;
    }
  }
  .text-placeholder {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    @media (min-width:960px) {
      width:60%;
    }
  }
  .text-wrapper {
    max-width: 375px;
  }
`
export default AboutSection
