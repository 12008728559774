import React from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle";
import {colors} from "../../variables";


const FooterBanner = styled(({className, secData, lang}) => {
    const indexOf = secData.title[lang].toLowerCase().indexOf('than');
    let title = "";

    if(indexOf > -1) {
        title = secData.title[lang].toLowerCase().slice(0,19) + "<br />" + secData.title[lang].toLowerCase().slice(19)
    }
    return <div className={className}>
        <div className="wrapper">
            <SectionTitle>{
                title !== "" ? <div dangerouslySetInnerHTML={{__html: title}} /> : secData.title[lang]
            }</SectionTitle>
            <div className="tags">
                <span>{secData['consultingList.consultingDiagnose'][lang]}</span>
                <span className="bordertag" />
                <span>{secData['consultingList.consultingRecomend'][lang]}</span>
                <span className="bordertag" />
                <span>{secData['consultingList.consultingImplement'][lang]}</span>
                <span className="bordertag" />
                <span>{secData['consultingList.consultingEffectivness'][lang]}</span>
            </div>
        </div>
    </div>
})`
  max-width:1200px;
  width:100%;
  margin:auto;
  padding-bottom: 70px;
  ${SectionTitle} {
   text-align: center;
    color: ${colors.orange};
    margin:110px 0 60px 0;
  },
  .wrapper {
    padding-left:25px;
    padding-right:25px;
  }
   .tags {
    display:flex;
    justify-content: center;
    align-items: center;
     flex-wrap:wrap;
  }
  span {
    width:100%;
    @media (min-width: 960px) {
      width:auto;
    }
    display: inline-block;
    color:${colors.orange};
    margin: 25px;
    text-align: center;
    @media (min-width:960px) {
      margin: 0;
    }
    &.bordertag {
      border:1px solid ${colors.orange};
      width:37px;
      height:0.5px;
      position:relative;
      margin: 0 7px;
      color:${colors.orange};
      background-color: ${colors.orange};
      @media (max-width:960px) {
        //display:none;
      }
    }
  }
`
export default FooterBanner
