import React from "react"
import styled from "styled-components"
import { Fade } from "react-reveal"
import {colors} from "../variables";

const BannerSubText = styled.h5`
  text-align: center;
  text-transform: uppercase;
  margin-bottom:0;
  font-weight: normal;
  color: ${colors.whiteShade};
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1200 - 300)));
  //@media (min-width:768px) {
  //  margin-bottom:10%;
  //}
`

const BannerText = styled.h1`
  font-size: calc(34px + (96 - 34) * ((100vw - 300px) / (1600 - 300)));
  font-weight:bold;
  text-transform: uppercase;
  text-align: center;
`

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  width:100%;
  margin:auto;
  max-width:1200px;
  padding-top:2%;
  border-right:1px solid ${colors.borderLineLighter};
  border-left: 1px solid ${colors.borderLineLighter};
  color: ${colors.whiteShade};
`

const Banner = styled.div`
  display: flex;
  width:100%;
  //height:100vh;
  flex-direction: column;
  //margin-top:80px;
  height:calc(80vh - 80px);
  @media (min-width:960px) {
    height:calc(90vh - 100px);
    margin-top:0;
  }
  background: ${colors.orange}
`
const BannerTop = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  padding:30px 0;
  border-bottom: 1px solid ${colors.borderLineLighter};
`

const CBanner = ({secData, lang}) => {
    const title = secData.title[lang].split(' ').map((item) => {
        return item.trim()
    }).join("<br />")
    return <Banner>
        <BannerTop>
            {/*<Fade bottom cascade>*/}
                <BannerSubText>
                    {/*Business design consulting*/}

                        <div>{secData.subtitle[lang]}</div>


                </BannerSubText>
            {/*</Fade>*/}
        </BannerTop>
        <BannerTextWrapper>
            {/*<BannerSubText>*/}
            {/*    /!*Business design consulting*!/*/}
            {/*    {secData.subtitle[lang]}*/}
            {/*</BannerSubText>*/}
            <Fade clear delay={300}>
                <BannerText dangerouslySetInnerHTML={{__html: title}} />
            </Fade>
                {/*<div dangerouslySetInnerHTML={{__html: title}} />*/}
            {/*</BannerText>*/}
        </BannerTextWrapper>
    </Banner>
}

export default CBanner;
