import React, {forwardRef, useState} from "react"
import styled from "styled-components"
import { useForm} from "react-hook-form";
import axios from "axios";
import SectionTitle from "../SectionTitle";
import { ImCheckmark, ImNotification } from "react-icons/im"
import {Zoom, Fade} from 'react-reveal';
import {colors} from "../../variables";
import { SocialIcons} from "../header";
import {BsFacebook, BsInstagram, BsLinkedin} from "react-icons/bs";

const localFormFields = {
    name: {
        label: {
            "sr": "Ime i Prezime",
            "en": "Full Name"
        },
        errors: {
            required: {
                "sr": "Ovo polje je obavezno",
                "en": "This field is required"
            }
        }
    },
    email: {
        label: {
            "sr": "E-mail",
            "en": "Email"
        },
        errors: {
            required: {
                "sr": "Ovo polje je obavezno",
                "en": "This field is required"
            },
            pattern: {
                "sr": "Ukucajte validan email",
                "en": "Please eneter valid email"
            }
        }
    },
    message: {
        label: {
            "sr": "Poruka",
            "en": "Your message"
        },
        errors: {
            required: {
                "sr": "Ovo polje je obavezno",
                "en": "This field is required"
            }
        }
    },
    submit: {
        sr: "Pošalji",
        en: "Send"
    }
}

const localSuccessMessage = {
    title: {
        sr: "Hvala što ste nas kontaktirali!",
        en: "Thank you for contacting us!"
    },
    subtitle_1: {
        sr: "Procesuiramo vaš zahtev.",
        en: "We are processing your request."
    },
    subtitle_2: {
        sr: "Naš tim će vas uskoro kontaktirati",
        en: "You can expect to hear from our team shortly."
    }
}



const ContactSection = styled(forwardRef(({className, secData, lang, department}, ref) => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [serverError , setServerError] = useState("")
    const [formSubmited, setFormSubmited] = useState(false)
    const validateEmail = {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
    }
    // const onSubmit = (data, e) => {
    //     e.preventDefault()
    //     console.log("Sending data...", data)
    // }
    const handleFormData = async (data) => {
        const fields = []
        for (let type in data) {
            fields.push({
                value: data[type],
                type: type.toUpperCase()
            })
        }
        const dataToSend = {
            identifier: process.env.WEBFRONT_ID,
            iso: "en",
            fields: fields
        }
        try {
            const response = await axios.post(process.env.FORM_CONTACT_API_URL || "" ,
                dataToSend,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    mode: 'cors',
                    cache: 'no-cache',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                }
            )
            setFormSubmited(true)
            setTimeout(() => {
                reset({
                    name:"",
                    email:"",
                    subject: "",
                    text: ""
                })
                setFormSubmited(false)
            }, 6000)
        }catch(error) {
            if (error.response) {
                setServerError(error.response.data.message)
            } else if (error.request) {

                // console.log(error.request);
            } else {
                // console.log('Error', error.message);
            }
        }
    }
    return (
        <div className={className}>
            <div id="contact" className="refhelper"  ref={ref} />
            <div className="bottom-line" />
            <div className="cf-wrapper">
                <div className="wrapper">
                    <div className="left-side">
                        <SectionTitle>{secData.title[lang]}</SectionTitle>
                        <div className="info">
                            <p>{department.location.address1} <br/>
                                {department.location.city}</p>
                            <p>{department.contacts.find(c => c.type === 'PHONE_PLAIN').value}</p>
                            <p>{department.contacts.find(c => c.type === 'EMAIL').value}</p>
                        </div>
                        <SocialIcons>
                            <div>
                                <a target="_blank" href={department.contacts.find(c => c.type === 'SOCIAL_FACEBOOK').value}><BsFacebook size={30}/></a>
                            </div>

                            <div>
                                <a target="_blank" href={department.contacts.find(c => c.type === 'SOCIAL_INSTAGRAM').value}><BsInstagram size={30}/></a>
                            </div>

                            <div>
                                <a target="_blank" href={department.contacts.find(c => c.type === 'SOCIAL_LINKEDIN')?.value || "#"}><BsLinkedin size={30}/></a>
                            </div>
                        </SocialIcons>
                    </div>
                    <div className="right-side">
                        { !formSubmited ? <div className="form">
                            <form onSubmit={handleSubmit(handleFormData)} method="POST">
                                <div className="input-field">
                                    <input type="text" {...register("name", { required: true})} placeholder={localFormFields.name.label[lang]}/>
                                    {errors.name && <span style={{color: "red", fontSize: "13px"}}>{localFormFields.name.errors.required[lang]}</span>}
                                </div>
                                <div className="input-field">
                                    <input {...register("email", {required: true, pattern: validateEmail})} type="email" placeholder={localFormFields.email.label[lang]}/>
                                    {errors.email?.type === "required" && <span style={{color: "red", fontSize: "13px"}}>{localFormFields.email.errors.required[lang]}</span>}
                                    {errors.email?.type === "pattern" &&  <span style={{color: "red", fontSize: "13px"}}>{localFormFields.email.errors.pattern[lang]}</span>}
                                </div>
                                <div className="input-field">
                                    <textarea {...register("text", {required: true})} cols="30" rows="10" placeholder={localFormFields.message.label[lang]} />
                                    {errors.message && <span style={{color: "red", fontSize: "13px"}}>{localFormFields.message.errors.required[lang]}</span>}
                                </div>
                                <button type="submit">{localFormFields.submit[lang]}</button>
                            </form>
                        </div> :   <Fade bottom delay={300}><div className="success-contact-form" style={{display: "flex", background: colors.whiteShade, color: colors.primaryText, textAlign:"center", flexDirection:"column", justifyContent: "center", alignItems: "center", width:"100%", height:"400px"}}>
                            <div>
                                { serverError ? <div>
                                        <div><ImNotification size="75px" color="red" /></div>
                                        <span style={{
                                            display: "inline-block",
                                            marginTop: "30px",
                                            fontSize: "2rem",
                                            fontWeight: "bold",
                                            marginBottom: "30px"
                                        }}>{serverError}</span>
                                    </div> :
                                    <Fade bottom delay={500}>

                                        <div><ImCheckmark size="75px" color="green" /></div>

                                        <span style={{
                                               display: "inline-block",
                                               marginTop: "30px",
                                               fontSize: "2rem",
                                               fontWeight: "bold",
                                               marginBottom: "30px",
                                               lineHeight: "2rem",
                                        }}>{localSuccessMessage.title[lang]}</span>
                                           <br />
                                           <br/>

                                        <p>{localSuccessMessage.subtitle_1[lang]} <br /> {localSuccessMessage.subtitle_2[lang]} </p>

                                    </Fade>
                                }
                            </div>
                        </div></Fade>}
                    </div>
                </div>
            </div>
        </div>
    )
}))`
  position: relative;
  .refhelper {
    position:relative;
    top:-30px;
    @media (min-width: 960px) {
      top:-70px;
    }
    visibility: hidden;
  }
  .bottom-line {
    position:absolute;
    top:100%;
    width:100%;
    height:7px;
    border-top: 1px solid ${colors.lightGreyText};
    border-bottom: 1px solid ${colors.lightGreyText};
  }
  .cf-wrapper {
    padding:0 25px;
  }
  ${SocialIcons} {
    padding-left:25px;
    margin-top: 25px;
    a {
      color:inherit;
      text-decoration:none;
      &:after, &:before {
        display:none;
      }
    }
    @media (min-width: 960px) {
      margin-top:125px;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    max-width: 1200px;
    margin:auto;
    padding:60px 0;
    height:initial;
    @media (min-width: 960px) {
      border-left:1px solid ${colors.lightGreyText};
      border-right:1px solid ${colors.lightGreyText};
    }
    @media (min-width: 768px) {
      height:670px;
    }
    ${SectionTitle} {
      padding-left:20px;
      height:40px;
    }
    // border-left:1px solid ${colors.lightGreyText};
    // border-right:1px solid ${colors.lightGreyText};
    .input-field {
      margin-bottom:20px;
    }
    input {
      //border: 1px solid ${colors.lightGreyText};
      &:focus {
        outline:none;
      }
      @media (min-width: 960px) {
        border: 1px solid ${colors.lightGreyText};
        border-right: none;
      }
      padding: 15px 15px;
      //min-width:400px;
      color: #000000;
      width:100%;
      //text-transform: uppercase;
    }
    input::placeholder {
      text-transform: uppercase;
    }
    textarea {
      //border: 1px solid ${colors.lightGreyText};
      &:focus {
        outline:none;
      }
      @media (min-width: 960px) {
        border-right: none;
        border: 1px solid ${colors.lightGreyText};
      }
      padding: 15px 15px;
      color: #000000;
      //min-width:400px;
      width:100%;
      &::placeholder {
        text-transform:uppercase;
      }
      //text-transform: uppercase;
    }
  }
  .form {
    margin-top:30px;
    @media (min-width: 960px) {
      margin-top:0;
    }
  }
  .right-side {
    width:100%;
    @media (min-width: 768px){
      width:50%;
    }
    button {
      background: ${colors.orange};
      color:white;
      font-weight: bold;
      padding: 10px 20px;
      text-transform: uppercase;
      box-shadow: none;
      outline: none;
      border:none;
      &:hover {
        cursor:pointer;
      }
    }
  }
  .left-side {
    // border-left:1px solid ${colors.lightGreyText};
    //border-right:1px solid ${colors.lightGreyText};
    width: 100%;
    @media (min-width: 768px){
      width:50%;
    }
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .info {
      @media (min-width: 960px) {
        margin-top:91px;
      }
      width:100%;
      //color: #000000;
      p {
        border-bottom: 1px solid ${colors.lightGreyText};
        @media (min-width:960px) {
          //border-top:1px solid ${colors.lightGreyText};
          border: none;
        }
        padding:5px 5px 5px 20px;
        margin-bottom:10px;
        @media (min-width: 960px) {
          margin:0;
        }
        &:first-child {
          border-bottom: 1px solid ${colors.lightGreyText};
          @media (min-width: 960px) {
            border-bottom: none;
          }
        }
        &:last-child {
          border-top: none;
        }
      }
    }
  }
`

export default ContactSection
