import React, {forwardRef} from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import ServiceList from "../ServiceList";
import {colors} from "../../variables";

const ServiceSection = styled(forwardRef(({className, secData, lang}, ref) => {
    return (<section className={className}>
        <div id="services" className="refhelper"  ref={ref} />
        <div className="title-wrapper">
            <div className="title-placeholder">
                <SectionTitle>{secData.title[lang]}</SectionTitle>
            </div>
        </div>
        <div className="services">
            {/*{secData.selectedModules.map((m, index) => {*/}
            {/*    return <div className="line" />*/}
            {/*})}*/}
            <ServiceList partners={secData.selectedModules} lang={lang} />
        </div>
    </section>)
}))`
  //.title-wrapper {
  //  display:flex;
  //  flex-direction: column;
  //  justify-content: space-between;
  //  flex-wrap:wrap;
  //  align-items: flex-start;
  //  max-width: 1200px;
  //  margin:auto;
  //}
  position:relative;
  .refhelper {
    position:relative;
    @media (min-width: 960px) {
      top:-30px;
    }
    visibility: hidden;
  }
  .title-wrapper {
    //padding:60px 25px 60px 25px;
    border-bottom: 1px solid ${colors.borderLineLight};
    height:150px;
  }
  .services {
    width:100%;
    max-width: 1200px;
    margin:auto;
    padding:60px 0;
    border-left: 1px solid ${colors.borderLineLight};
    border-right: 1px solid ${colors.borderLineLight};
  }
  .line {
    height:0;
    position:absolute;
    width:100%;
    border:1px solid ${colors.lightGreyText};
  }
  .title-placeholder {
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight:bold;
    max-width: 1200px;
    margin:0 auto;
    padding-left:25px;
    padding-bottom:15px;
    border-left:1px solid ${colors.borderLineLight};
    border-right: 1px solid ${colors.borderLineLight};
    h3 {
      margin-bottom: 0;
    }
    //font-size: calc(34px + (96 - 34) * ((100vw - 300px) / (1600 - 300)));
    width:100%;
    //@media (min-width:960px) {
    //  width:40%;
    //}
  }
`
export default ServiceSection
