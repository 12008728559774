import React from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import ServiceList from "../ServiceList";
import PartnerList from "../PartnerList";
import {colors} from "../../variables";

const PartnerSection = styled(({className, secData, lang}) => {
    return (<section className={className}>
        <div className="bottom-line" />
        <div className="title-wrapper">
            <div className="title-placeholder">
                <SectionTitle>{secData.title[lang]}</SectionTitle>
            </div>
        </div>
        <div className="partner-wrapper">
            <div className="partners">
                <PartnerList partners={secData.selectedModules} lang={lang} />
            </div>
        </div>
    </section>)
})`
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: flex-start;
  margin:auto;
  background: ${colors.lightGreyText};
  ${SectionTitle} {
    color: ${colors.orange};
  }
  .bottom-line {
    border-color: ${colors.whiteShade};
  }
  .title-wrapper {
    display: flex;
    width:100%;
    border-bottom: 1px solid ${colors.whiteShade};
  }
  .partner-wrapper {
    max-width:1200px;
    margin:auto;
    width:100%;
    border-left: 1px solid ${colors.whiteShade};
    border-right: 1px solid ${colors.whiteShade};
  }
  .partners {
    width:100%;
    max-width:1200px;
    margin:auto;
    //padding-left:25px;
    padding-bottom: 70px;
    display:flex;
  }
  .title-placeholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight:bold;
    //font-size: calc(34px + (96 - 34) * ((100vw - 300px) / (1600 - 300)));
    width:100%;
    height:150px;
    padding-bottom:25px;
    max-width:1200px;
    border-left: 1px solid ${colors.whiteShade};
    border-right: 1px solid ${colors.whiteShade};
    margin: auto;
    padding-left:25px;
    h3 {
      margin-bottom:0;
    }
    //padding:100px 25px;
    //@media (min-width:960px) {
    //  width:40%;
    //}
  }
  //padding:100px 25px;
`
export default PartnerSection
