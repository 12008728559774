import React from "react"
import styled from "styled-components"
import {colors} from "../variables";

const ServiceItem = styled(({className, title, content}) => {
    return (
        <div className={className}>
            <div className="topTitle">
                <h3>{title}</h3>
            </div>
            <div className="content">
                <div className="text">
                    {/*<ul>*/}
                    {/*    <li>Poslovna analiza</li>*/}
                    {/*    <li>Strategijski menadžment</li>*/}
                    {/*    <li>Operativni menadžment</li>*/}
                    {/*    <li>Organizacione promjene</li>*/}
                    {/*    <li>Implementacija poslovnog softvera</li>*/}
                    {/*    <li>Izrada i implementacija biznis planova</li>*/}
                    {/*</ul>*/}
                    <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
            </div>
        </div>
    )
})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height:400px;
    width:100%;
    align-self: flex-start;
    @media (min-width: 960px) {
      width:33%;
    }
    .topTitle {
      border-left: 6px solid ${colors.orange};
      padding: 65px 10px 10px 10px;
      text-transform: uppercase;
      color: ${colors.orange};
      min-height: 130px;
      max-width:310px;
      h3 {
        margin-bottom:0;
        //font-size: calc(24px + (34 - 20) * ((100vw - 300px) / (1600 - 300)));
        font-size:30px;
      }
    }
    .content {
      padding:35px 25px 10px 10px;
      width:100%;
      ul {
        list-style-type: none;
        padding:0;
        margin:0;
        li {
          margin-bottom:10px;
          border-bottom: 1px dashed #dfd5c9;
        }
      }
    }
`

const ServiceList = styled(({className, partners, lang}) => {
    // const locales = partners.map( p => {
    //     return
    // })
    return (
        <div className={className}>
            { partners.map((p) => {
                return <ServiceItem key={p.uid} title={p.locales.find(l => l.iso === lang).title} content={p.locales.find(l => l.iso === lang).content} />
            })}
            {/*<ServiceItem />*/}
            {/*<ServiceItem />*/}
            {/*<ServiceItem />*/}
        </div>
    )
})`
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  //margin-top:60px;
  flex-wrap:wrap;
  padding-left:25px;
`

export default ServiceList
