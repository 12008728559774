import React from "react"
import styled from "styled-components"
// import Img from "gatsby-image";
import { Fade } from "react-reveal"
import {sortModulesAndAssets} from "../../plugins/berry-plugin/util/helpers"
import {colors} from "../variables";
import { getImage, GatsbyImage} from "gatsby-plugin-image"

const ClientList = styled(({className, lang, clients}) => {
    // console.log(sortModulesAndAssets(clients[0].gallery.galleryImages))
    return (
        <div className={className}>
            {sortModulesAndAssets(clients[0].gallery.galleryImages).map((c, index) => {

                return  <div key={index} className="client">
                    <Fade bottom delay={50*index}>
                        <div>
                            {/*<Img fluid={c.galleryImage.childImageSharp.fluid} />*/}
                            <GatsbyImage image={getImage(c.galleryImage)} alt={clients[0].gallery.assets[index].locales.find(l => l.iso === lang).alt} />
                        </div>
                    </Fade>
                </div>
            })}

        </div>
    )
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:wrap;
  .client {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height:200px;
    img {
      display:block;
      width:100%;
    }
    width:100%;
    padding: 27px;
    //border:1px solid ${colors.orange};
    &:first-child {
      border-top:none;
    }
    border-bottom: 1px solid ${colors.whiteShade};
    @media (min-width: 300px) {
      border-left: 1px solid ${colors.whiteShade};
      &:nth-child(odd) {
        border-left: 1px solid ${colors.whiteShade};
      }
    }
    @media (min-width: 960px) {
      &:last-child {
        border-right: 1px solid ${colors.whiteShade};
      }
    }
    @media (min-width: 1200px) {
      &:nth-child(5n + 1) {
        border-left:none;
      }
      &:last-child {
        border-right: none;
      }
    }
   
    //margin-bottom: 20px;
    //display: flex;
    //align-self: flex-start;
    @media (min-width: 300px) {
      width:50%;
    }
    @media (min-width: 968px) {
      width:25%;
    }
    @media (min-width: 1200px) {
      width:20%;
    }
  }
`
export default ClientList
