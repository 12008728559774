import React from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle";
import ClientList from "../ClientList";
import {colors} from "../../variables";

const ClientSection = styled(({className, secData, lang}) => {
    return (
        <div className={className}>
            <div className="title-wrapper">
                <div className="title-placeholder">
                    <SectionTitle>{secData.title[lang]}</SectionTitle>
                </div>
            </div>
           <div className="wrapper">
               {/*<SectionTitle>{secData.title[lang]}</SectionTitle>*/}
               <div className="clients">
                   <ClientList clients={secData.selectedModules} lang={lang} />
               </div>
           </div>
        </div>
    )
})`
  width:100%;
  background: ${colors.lightGreyText};
  .title-wrapper {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: 1px solid ${colors.whiteShade};
  }
  .title-placeholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-end;
    text-transform: uppercase;
    height:150px;
    padding-bottom:25px;
    max-width:1200px;
    width:100%;
    border-left: 1px solid ${colors.whiteShade};
    border-right: 1px solid ${colors.whiteShade};
    margin: auto;
    padding-left:25px;
    h3 {
      margin-bottom:0;
    }
  }
  .wrapper {
    max-width:1200px;
    margin:auto;
    width:100%;
    //padding:60px 25px;
  }
  .clients {
    //margin-top:60px;
    padding-bottom:35px;
    //border-bottom: 1px solid ${colors.whiteShade};
    border-left: 1px solid ${colors.whiteShade};
    border-right: 1px solid ${colors.whiteShade};
  }
  ${SectionTitle} {
    color: ${colors.orange};
  }
`
export default ClientSection
