import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CBanner from "../components/Banner";
import AboutSection from "../components/Homepage/AboutSection";
import ServiceSection from "../components/Homepage/ServiceSection";
import PartnerSection from "../components/Homepage/PartnerSection";
import ClientSection from "../components/Homepage/ClientSection";
import ContactSection from "../components/Homepage/ContactSection";
import FooterBanner from "../components/Homepage/FooterBanner";

const IndexPage = ({data, pageContext, location}) => {
    const contactSectionRef = useRef()
    const serviceSectionRef = useRef()
    const refs = {
        contactRef: contactSectionRef,
        serviceRef: serviceSectionRef
    }
    const lang = pageContext.lang
    const metaInfo = pageContext.metaInfo
    const pageData = pageContext.pageData

   return ( <Layout department={data.department} location={location} lang={lang} refs={refs} loclales={pageContext.locales} slug={pageContext.slug} otherLang={pageContext.otherLang} defLang={pageContext.defLang}>
        <Seo
            title={metaInfo.metaTitle}
            description={metaInfo.metaDescription}
        />
           <CBanner secData={pageData.banner} lang={lang}/>
           <AboutSection secData={pageData.about} lang={lang} />
           <ServiceSection ref={serviceSectionRef} secData={pageData.service} lang={lang} />
            <ClientSection secData={pageData.client} lang={lang} />
           <PartnerSection secData={pageData.partner} lang={lang} />
           <ContactSection department={data.department} ref={contactSectionRef} secData={pageData.contact} lang={lang} />
           <FooterBanner secData={pageData.footerBanner} lang={lang} />

    </Layout>)
}

export const query = graphql`
  {
      department(name: {eq: "main"}) {
        contacts {
          type
          value
        }
        location {
          address1
          city
        }
      }
  }
`

export default IndexPage
