import React from "react"
import styled from "styled-components"
// import Img from "gatsby-image"
import {getImage, GatsbyImage} from "gatsby-plugin-image"
import {colors} from "../variables";


const PartnerItem = styled(({className, img, content, alt, url}) => {
    const image = getImage(img)
    return (
        <div className={className}>
            <div className="partner-img">
               {/*<Img fluid={img} />*/}
                <a href={url} target="__blank">
                    <GatsbyImage image={image} alt="Partner logo" />
                </a>
            </div>
            <div className="content">
                <div style={{maxWidth:"400px"}} dangerouslySetInnerHTML={{__html: content}} />
            </div>
        </div>
    )
})`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap:nowrap;
  align-items: flex-start;
  width:100%;
  @media (min-width: 768px) {
    width:50%;
  }
  padding: 30px;
  border-top:1px solid ${colors.whiteShade};
  //border-bottom: 1px solid ${colors.whiteShade};
  @media (min-width: 768px) {
    &:nth-child(2n + 1) {
      border-right: 1px solid ${colors.whiteShade};
    }
  }
  &:first-child {
    border-top:none;
  }
  //&:nth-child(even) {
  //  flex-direction: row-reverse;
  //}
  .partner-img {
    width:100%;
    @media (min-width: 768px){
      width:45%;
    }
    margin-bottom:30px;
    height:200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //max-width:150px;
    //min-width:250px;
    img {
      //max-height:100px !important;
      //width:auto !important;
      width:100%;
      max-width:250px !important;
      max-height:150px;
      object-fit:contain !important;
    }
    //@media (min-width: 960px) {
    //  width:40%
    //}
  }
  .content {
    width:100%;
    //height:60%;
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //@media (min-width: 960px) {
    //  width:60%;
    //}
  }
  img {
    display: block;
    width:100%;
  }
`

const PartnerList = styled(({className, partners, lang}) => {
    return (
        <div className={className}>
            {partners.map((p, index) => {
                return <PartnerItem alt={p.asset.locales.find(l => l.iso === lang).alt} key={index} img={p.imageFile} content={p.locales.find(l => l.iso === lang).content} url={p.url} />
            })}
        </div>
    )
})`
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  border-bottom: 1px solid ${colors.whiteShade};
  //padding:70px 0;
`

export default PartnerList
